/* eslint-disable @typescript-eslint/naming-convention */
export const environment = {
  production: true,
  hmr: false,


  // API_URL: 'http://localhost:8000/api/admin/',
  // API_URL: 'https://inmoapp-api.foxcode.com.mx/api/admin/',

  // API_URL_LANDING: 'http://localhost:8000/api/landing/',
  // API_URL_LANDING: 'https://inmoapp-api.foxcode.com.mx/api/landing/',

  // URL: 'http://localhost:8000/api/',
  // URL: 'https://inmoapp-api.foxcode.com.mx/api/',
  // URL: 'https://inmoapp-api.foxcode.com.mx.app',

  // URL_LANDING: 'http://localhost:8000/',
  // URL_LANDING: 'https://inmoapp-api.foxcode.com.mx/',
  // URL_LANDING: 'https://in-mo.app/',


  API_URL: 'https://back.in-mo.app/api/admin/',
  API_URL_LANDING: 'https://back.in-mo.app/api/landing/',
  URL: 'https://back.in-mo.app',
  URL_LANDING: 'https://in-mo.app/',

  URL_SUBDOMAIN_LANDING: '.inmoapp.mx',
  URL_AWS: 'https://inmoapp-storage.s3.us-west-1.amazonaws.com/',
  MAPS_API_KEY: 'AIzaSyAyZQmz1DdyZaHSOrN4cI-2B9762yTJdiQ',
};
